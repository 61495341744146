import { render, staticRenderFns } from "./SignUpForm.vue?vue&type=template&id=1508d4b2"
import script from "./SignUpForm.vue?vue&type=script&lang=ts"
export * from "./SignUpForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports